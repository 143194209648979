body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



@font-face {
  font-family: "Gilroy";
  src: local("Gilroy-UltraLight.ttf"), url("/src/assets/fonts/gilroy/Gilroy-UltraLight.ttf") format("truetype");
  font-display: swap;
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy-UltraLightItalic.ttf"), url("/src/assets/fonts/gilroy/Gilroy-UltraLightItalic.ttf") format("truetype");
  font-display: swap;
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy-Thin.ttf"), url("/src/assets/fonts/gilroy/Gilroy-Thin.ttf") format("truetype");
  font-display: swap;
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy-ThinItalic.ttf"), url("/src/assets/fonts/gilroy/Gilroy-ThinItalic.ttf") format("truetype");
  font-display: swap;
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy-Light.ttf"), url("/src/assets/fonts/gilroy/Gilroy-Light.ttf") format("truetype");
  font-display: swap;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy-LightItalic.ttf"), url("/src/assets/fonts/gilroy/Gilroy-LightItalic.ttf") format("truetype");
  font-display: swap;
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy-Regular.ttf"), url("/src/assets/fonts/gilroy/Gilroy-Regular.ttf") format("truetype");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy-RegularItalic.ttf"), url("/src/assets/fonts/gilroy/Gilroy-RegularItalic.ttf") format("truetype");
  font-display: swap;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy-Medium.ttf"), url("/src/assets/fonts/gilroy/Gilroy-Medium.ttf") format("truetype");
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy-MediumItalic.ttf"), url("/src/assets/fonts/gilroy/Gilroy-MediumItalic.ttf") format("truetype");
  font-display: swap;
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy-Bold.ttf"), url("/src/assets/fonts/gilroy/Gilroy-Bold.ttf") format("truetype");
  font-display: swap;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy-Bold.ttf"), url("/src/assets/fonts/gilroy/Gilroy-BoldItalic.ttf") format("truetype");
  font-display: swap;
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy-Black.ttf"), url("/src/assets/fonts/gilroy/Gilroy-Black.ttf") format("truetype");
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy-BlackItalic.ttf"), url("/src/assets/fonts/gilroy/Gilroy-BlackItalic.ttf") format("truetype");
  font-display: swap;
  font-weight: 700;
  font-style: italic;
}




@font-face {
  font-family: "EuclidCircularA";
  src: local("Euclid Circular A Light.ttf"), url("/src/assets/fonts/euclid-a/Euclid Circular A Light.ttf") format("truetype");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "EuclidCircularA";
  src: local("Euclid Circular A Italic.ttf"), url("/src/assets/fonts/euclid-a/Euclid Circular A Italic.ttf") format("truetype");
  font-display: swap;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "EuclidCircularA";
  src: local("Euclid Circular A Regular.ttf"), url("/src/assets/fonts/euclid-a/Euclid Circular A Regular.ttf") format("truetype");
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "EuclidCircularA";
  src: local("Euclid Circular A Italic.ttf"), url("/src/assets/fonts/euclid-a/Euclid Circular A Italic.ttf") format("truetype");
  font-display: swap;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "EuclidCircularA";
  src: local("Euclid Circular A Medium.ttf"), url("/src/assets/fonts/euclid-a/Euclid Circular A Medium.ttf") format("truetype");
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "EuclidCircularA";
  src: local("Euclid Circular A Medium Italic.ttf"), url("/src/assets/fonts/euclid-a/Euclid Circular A Medium Italic.ttf") format("truetype");
  font-display: swap;
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "EuclidCircularA";
  src: local("Euclid Circular A SemiBold.ttf"), url("/src/assets/fonts/euclid-a/Euclid Circular A SemiBold.ttf") format("truetype");
  font-display: swap;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "EuclidCircularA";
  src: local("Euclid Circular A SemiBold Italic.ttf"), url("/src/assets/fonts/euclid-a/Euclid Circular A SemiBold Italic.ttf") format("truetype");
  font-display: swap;
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "EuclidCircularA";
  src: local("Euclid Circular A Bold.ttf"), url("/src/assets/fonts/euclid-a/Euclid Circular A Bold.ttf") format("truetype");
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "EuclidCircularA";
  src: local("Euclid Circular A Bold Italic.ttf"), url("/src/assets/fonts/euclid-a/Euclid Circular A Bold Italic.ttf") format("truetype");
  font-display: swap;
  font-weight: 700;
  font-style: italic;
}


@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B Light.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B Light.ttf") format("truetype");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B Italic.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B Italic.ttf") format("truetype");
  font-display: swap;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B Regular.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B Regular.ttf") format("truetype");
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B Italic.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B Italic.ttf") format("truetype");
  font-display: swap;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B Medium.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B Medium.ttf") format("truetype");
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B Medium Italic.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B Medium Italic.ttf") format("truetype");
  font-display: swap;
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B SemiBold.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B SemiBold.ttf") format("truetype");
  font-display: swap;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B SemiBold Italic.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B SemiBold Italic.ttf") format("truetype");
  font-display: swap;
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B Bold.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B Bold.ttf") format("truetype");
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B Bold Italic.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B Bold Italic.ttf") format("truetype");
  font-display: swap;
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "TTSquares";
  src: local("Squares Light.otf"), url("/src/assets/fonts/tt-squares/Squares Light.otf") format("opentype");
  font-display: swap;
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "TTSquares";
  src: local("Squares Light Italic.otf"), url("/src/assets/fonts/tt-squares/Squares Light italic.otf") format("opentype");
  font-display: swap;
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "TTSquares";
  src: local("Squares Thin.otf"), url("/src/assets/fonts/tt-squares/Squares Thin.otf") format("opentype");
  font-display: swap;
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "TTSquares";
  src: local("Squares Thin Italic.otf"), url("/src/assets/fonts/tt-squares/Squares Thin Italic.otf") format("opentype");
  font-display: swap;
  font-weight: 200;
  font-style: italic;
}


@font-face {
  font-family: "TTSquares";
  src: local("Squares Regular.otf"), url("/src/assets/fonts/tt-squares/Squares Regular.otf") format("opentype");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}


@font-face {
  font-family: "TTSquares";
  src: local("Squares Italic.otf"), url("/src/assets/fonts/tt-squares/Squares Italic.otf") format("opentype");
  font-display: swap;
  font-weight: 400;
  font-style: italic;
}


@font-face {
  font-family: "TTSquares";
  src: local("Squares Bold.otf"), url("/src/assets/fonts/tt-squares/Squares Bold.otf") format("opentype");
  font-display: swap;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "TTSquares";
  src: local("Squares Bold Italic.otf"), url("/src/assets/fonts/tt-squares/Squares Bold Italic.otf") format("opentype");
  font-display: swap;
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "TTSquares";
  src: local("Squares Bold Italic.otf"), url("/src/assets/fonts/tt-squares/Squares Black.otf") format("opentype");
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "TTSquares";
  src: local("Squares Black.otf"), url("/src/assets/fonts/tt-squares/Squares Black Italic.otf") format("opentype");
  font-display: swap;
  font-weight: 700;
  font-style: italic;
}


@font-face {
  font-family: "GoogleSans";
  src: local("Euclid Circular B Light.ttf"), url("/src/assets/fonts/google-sans/GoogleSans-Regular.ttf") format("truetype");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}



@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B Light.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B Light.ttf") format("truetype");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B Italic.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B Italic.ttf") format("truetype");
  font-display: swap;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B Regular.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B Regular.ttf") format("truetype");
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B Italic.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B Italic.ttf") format("truetype");
  font-display: swap;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B Medium.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B Medium.ttf") format("truetype");
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B Medium Italic.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B Medium Italic.ttf") format("truetype");
  font-display: swap;
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B SemiBold.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B SemiBold.ttf") format("truetype");
  font-display: swap;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B SemiBold Italic.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B SemiBold Italic.ttf") format("truetype");
  font-display: swap;
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B Bold.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B Bold.ttf") format("truetype");
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B Bold Italic.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B Bold Italic.ttf") format("truetype");
  font-display: swap;
  font-weight: 700;
  font-style: italic;
}
